import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import DeviceHero from '../../components/DeviceHero';
import Wayfinding from '../../components/Wayfinding';
import DeviceSection from '../../components/DeviceSection';
import DemoBanner from '../../components/DemoBanner';
import SideBySideSection from '../../components/SideBySideSection';
import ResourcesSection from '../../components/ResourcesSection';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import SiteMeta from '../../components/SiteMeta';

const PlanPage = ({ data, location }) => {

  // TODO: FPO waiting for content model fixes to hook up contentful

  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroModule,
    planIntroductionModule,
    planInfoModule1,
    planInfoModule2,
    planInfoModule3,
    demoCallout,
    wayfindingCardTitle,
    wayfindingCardSubheader: { wayfindingCardSubheader },
    wayfindingCards,
    featuredResources,
    featuredResourcesTitle,
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText }, } = data.contentfulPlanPage;

  const wayfindingData = {
    title: wayfindingCardTitle,
    subtitle: wayfindingCardSubheader,
    items: wayfindingCards.map(
      ({ wayfindingCardTitle, wayfindingCardBody, matchUrl }) => {
        return {
          title: wayfindingCardTitle,
          body: wayfindingCardBody.wayfindingCardBody,
          matchUrl,
        };
      },
    ),
    currentPath: location.pathname,
  };

  const deviceSections = [
    planInfoModule1,
    planInfoModule2,
    planInfoModule3,
  ]

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  const heroDeviceData = deviceDataFromDeviceAsset(heroModule.deviceAsset);

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <DeviceHero {...heroModule} {...heroDeviceData} />
      <SideBySideSection layoutVariant={'mediaRight'} barTheme={'transparent'} {...planIntroductionModule} />
      {deviceSections.map((section, index) => {
        const layout = index % 2 ? 'deviceRight' : 'deviceLeft';
        const deviceData = deviceDataFromDeviceAsset(section.deviceAsset)
        return (
          <DeviceSection {...section} {...deviceData} key={index} layoutVariant={layout} />
        );
      })}
      {/* <Wayfinding {...wayfindingData} /> */}
      <DemoBanner {...demoCallout} theme="dark" />
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default PlanPage;

export const query = graphql`
  query {
    contentfulPlanPage{
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...DeviceHero
      }
      planIntroductionModule {
        ...Navigator
      }
      planInfoModule1 {
        ...DeviceNavigator
      }
      planInfoModule2 {
        ...DeviceNavigator
      }
      planInfoModule3 {
        ...DeviceNavigator
      }
      wayfindingCardTitle
      wayfindingCardSubheader {
        wayfindingCardSubheader
      }
      wayfindingCards {
        ...WayfindingCard
      }
      demoCallout {
        title
        ctaLink
        ctaText
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
    }
  }
`;

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MediaItem from '../MediaItem';
import RichText from '../RichText';
import { Button, ButtonContainer } from '../Button';
import isMobile from '../../utils/isMobile';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const SideBySideSection = ({
  asset,
  videoCoverImage,
  title,
  ctaText,
  ctaLink,
  bodyText,
  layoutVariant,
  barTheme,
  backgroundTheme,
  centerTitle,
}) => {
  const hasCTA = ctaText && ctaLink;

  const introRef = useRef();
  const sectionRef = useRef();
  const barRef = useRef();
  const contentRef = useRef();
  const mediaRef = useRef();
  const isMobileLayout = isMobile();
  const [ animation, setAnimation ] = useState(null);

  useEffect(() => {
    const mediaEl = mediaRef.current;
    const contentEl = contentRef.current;
    const barEl = barRef.current;
    const introEl = introRef.current;
    const toCommon = {
      duration: 0.5,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.25}`;

    const InFromRight = [
      {
        opacity: 0,
        x: '25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromLeft = [
      {
        opacity: 0,
        x: '-25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const IntroAnimation = [
      {
        opacity: 0,
        y: '120%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
        duration: centerTitle ? toCommon.duration : 0,
      },
      centerTitle ? delayCommon : 0,
    ];

    const entranceDirection = isMobileLayout
        ? InFromBottom
        : layoutVariant === 'mediaLeft'
            ? InFromLeft
            : InFromRight;

    const animation = gsap
        .timeline({ paused: true })
        .fromTo(introEl, ...IntroAnimation)
        .fromTo(barEl, ...entranceDirection)
        .fromTo(mediaEl, ...entranceDirection)
        .fromTo(contentEl, ...InFromBottom);

    setAnimation(animation);

    return () => {
      animation.progress(1);
      animation.kill();
    };
  }, [ isMobileLayout ]);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      onEnter: () => {
        if (animation) {
          animation.play();
        }
      },
    });

    return () => {
      trigger.kill();
    };
  }, [ animation ]);

  return (
      <section
          ref={sectionRef}
          className={`side-by-side-section side-by-side-section--${layoutVariant} side-by-side-section--bar-${barTheme} side-by-side-section--background-${backgroundTheme}`}
      >
        <div ref={barRef} className="side-by-side-section__bar"/>
        {centerTitle &&
            <div
                className={`side-by-side-section__intro side-by-side-section__intro-${backgroundTheme}`}
            >
              <h2
                  ref={introRef}
                  className="side-by-side-section__title"
              >
                {title}
              </h2>
            </div>
        }
        <div className="side-by-side-section__inner">
          {/* MEDIA */}

          <div ref={mediaRef} className="side-by-side-section__media">
            <MediaItem
                videoCoverImage={videoCoverImage}
                className="side-by-side-section__media-item"
                asset={asset}
            />
          </div>

          {/* CONTENT */}
          <div ref={contentRef} className="side-by-side-section__content">
            <div className="side-by-side-section__content-inner">
              {!centerTitle && <h2 className="side-by-side-section__title">{title}</h2>}
              <RichText source={bodyText.json}/>
            </div>

            {hasCTA && (
                <ButtonContainer align="left">
                  <Button href={ctaLink} className="side-by-side-section__cta">
                    {ctaText}
                  </Button>
                </ButtonContainer>
            )}
          </div>
        </div>
      </section>
  );
};

SideBySideSection.propTypes = {
  layoutVariant: PropTypes.oneOf([ 'mediaLeft', 'mediaRight' ]),
  barTheme: PropTypes.oneOf([ 'default', 'orange', 'transparent', 'yellow' ]),
  backgroundTheme: PropTypes.oneOf([ 'default', 'white', 'grey' ]),
};

SideBySideSection.defaultProps = {
  layoutVariant: 'mediaLeft',
  barTheme: 'default',
  videoCoverImage: null,
  backgroundTheme: 'default',
};

export default SideBySideSection;

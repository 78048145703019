import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DeviceFrame from '../DeviceFrame';
import SectionIntro from '../SectionIntro';
import RichText from '../RichText';
import { Button, ButtonContainer } from '../Button';

import isMobile from '../../utils/isMobile';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const DeviceSection = (props) => {
  const {
    title,
    subheaderText,
    body,
    layoutVariant,
    deviceType,
    barTheme,
    ctaText,
    ctaLink,
    backgroundTheme,
    fallbackImage,
    asset,
  } = props;

  const hasIntro = title && subheaderText;

  const sectionRef = useRef();
  const barRef = useRef();
  const contentRef = useRef();
  const introRef = useRef();
  const deviceRef = useRef();

  const isMobileLayout = isMobile();

  const hasCTA = ctaText && ctaLink;
  
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    const deviceEl = deviceRef.current;
    const contentEl = contentRef.current;
    const barEl = barRef.current;
    const introEl = introRef.current;

    const toCommon = {
      duration: 0.5,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.25}`;

    const InFromRight = [
      {
        opacity: 0,
        x: '25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromLeft = [
      {
        opacity: 0,
        x: '-25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const IntroAnimation = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
        duration: hasIntro ? toCommon.duration : 0,
      },
      hasIntro ? delayCommon : 0,
    ];

    const entranceDirection = isMobileLayout
      ? InFromBottom
      : layoutVariant === 'deviceLeft'
      ? InFromLeft
      : InFromRight;

    const animation = gsap
      .timeline({ paused: true })
      .fromTo(introEl, ...IntroAnimation)
      .fromTo(barEl, ...entranceDirection)
      .fromTo(deviceEl, ...entranceDirection)
      .fromTo(contentEl, ...InFromBottom);
      
    setAnimation(animation);
      
    return () => {
      animation.progress(1);
      animation.kill();
    };
  }, [isMobileLayout]);


  useEffect(() => {
    const sectionEl = sectionRef.current;
    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      onEnter: () => {
        if (animation) {
          animation.play();
        }
      },
      ...inViewTriggerThresholds,
      top: `0% 0%`,
    });
    return () => {
      trigger.kill();
    };
  }, [animation]);

  return (
    <div
      ref={sectionRef}
      className={`device-section device-section--${layoutVariant} device-section--${deviceType} device-section--bar-${barTheme} device-section--background-${backgroundTheme} device-section--${
        hasIntro ? 'withIntro' : 'noIntro'
      }`}
    >
      {hasIntro && (
        <div ref={introRef} className="device-section__intro">
          <SectionIntro title={title} subtitle={subheaderText} />
        </div>
      )}

      <div className="device-section__inner">
        <div className="device-section__device">
          <div ref={deviceRef} className="device-section__device-inner">
            <DeviceFrame
              asset={asset}
              fallbackImage={fallbackImage}
              deviceType={deviceType}
            />
          </div>
          <div className="device-section__bar" ref={barRef} />
        </div>
        <div ref={contentRef} className="device-section__content">
          <div className="device-section__content-inner">
            {body?.json && <RichText source={body.json} />}
            {hasCTA && (
              <ButtonContainer align="left">
                <Button href={ctaLink} className="device-section__cta">
                  {ctaText}
                </Button>
              </ButtonContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DeviceSection.propTypes = {
  layoutVariant: PropTypes.oneOf(['deviceLeft', 'deviceRight']),
  deviceType: PropTypes.oneOf(['laptop', 'tablet', 'mobile']),
  backgroundTheme: PropTypes.oneOf(['default', 'white', 'grey']),
  barTheme: PropTypes.oneOf([
    'default',
    'transparent',
    'yellow',
    'orange',
    'dark-grey',
  ]),
};

DeviceSection.defaultProps = {
  layoutVariant: 'deviceLeft',
  deviceType: 'laptop',
  asset: null,
  backgroundTheme: 'default',
  barTheme: 'default',
};

export default DeviceSection;
